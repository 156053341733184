import { default as abuseReportsGUpO7fGafEMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93sf05ekaTpJMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexwcUry2mvjsMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/index.vue?macro=true";
import { default as moderationZEzxXZrotwMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/moderation.vue?macro=true";
import { default as pendingBansa1qkJ0TVgjMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/pendingBans.vue?macro=true";
import { default as profiles0x3Re43tllMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/profiles.vue?macro=true";
import { default as expensesrVfUpfM2QvMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexrEjHTYxCfLMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewgjqM5IxEXyMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingf8RGZXgZD6Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/translations/awaiting.vue?macro=true";
import { default as missing1bz4bGgKG0Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/translations/missing.vue?macro=true";
import { default as usersOVhYUC2SfiMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/admin/users.vue?macro=true";
import { default as api4B1ltHWa3QMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/api.vue?macro=true";
import { default as _91slug_93WC7ilTvZT4Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/blog/[slug].vue?macro=true";
import { default as index62cDiHWND9Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93ZUz5Aho6i6Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93yRpsUNTVkHMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminRXxLFHIz9BMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/census/admin.vue?macro=true";
import { default as indexEXnRI3zxmHMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/census/index.vue?macro=true";
import { default as contactWodbRSUlACMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/contact.vue?macro=true";
import { default as designO9z6gPQB92Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/design.vue?macro=true";
import { default as englishF6zpKnEuyqMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/english.vue?macro=true";
import { default as faqLIWJHrOyqNMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/faq.vue?macro=true";
import { default as inclusive34lXGGRt8YMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/inclusive.vue?macro=true";
import { default as indexv8OSBGRrhqMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/index.vue?macro=true";
import { default as licenseDfoofIPxyoMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/license.vue?macro=true";
import { default as academic0Cy8nfsTmZMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/links/academic.vue?macro=true";
import { default as index0PlJCdV3lnMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/links/index.vue?macro=true";
import { default as mediatRSbr0y2pjMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/links/media.vue?macro=true";
import { default as translinguisticsemisgl6lJeMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/links/translinguistics.vue?macro=true";
import { default as zineXrTG4AX8ZFMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/links/zine.vue?macro=true";
import { default as namesSPKgiRAGlvMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/names.vue?macro=true";
import { default as indexK6GA9OMZKQMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/nouns/index.vue?macro=true";
import { default as templatesjpzOArIFNyMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/nouns/templates.vue?macro=true";
import { default as peopleo3b1W9Fix9Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/people.vue?macro=true";
import { default as privacy8dBWkUtVbIMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/privacy.vue?macro=true";
import { default as _91username_937DN9MxpMEFMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93WoDDUtS9NsMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/profile/card-[username].vue?macro=true";
import { default as editorCfXzXxm5PjMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/profile/editor.vue?macro=true";
import { default as anydu83tNmOtUMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/any.vue?macro=true";
import { default as avoidinglO6bRQKuIHMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/avoiding.vue?macro=true";
import { default as indexPNiPKM455uMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/index.vue?macro=true";
import { default as mirrornG5VPyVjyHMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/mirror.vue?macro=true";
import { default as pronounEtT2ipq6mKMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/pronoun.vue?macro=true";
import { default as sourceseAHVmR7ugnMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/sources.vue?macro=true";
import { default as teamPZVupOt49AMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/team.vue?macro=true";
import { default as terminologyw2EOEdY934Meta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/terminology.vue?macro=true";
import { default as termsDh7H2h9hlsMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/terms.vue?macro=true";
import { default as userMECsLPlgmnMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/user.vue?macro=true";
import { default as workshopsHujk2Ax0UeMeta } from "/home/admin/www/pronombr.es/release/20240927120726/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsGUpO7fGafEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansa1qkJ0TVgjMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: api4B1ltHWa3QMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93WC7ilTvZT4Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: index62cDiHWND9Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93ZUz5Aho6i6Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93yRpsUNTVkHMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminRXxLFHIz9BMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexEXnRI3zxmHMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactWodbRSUlACMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishF6zpKnEuyqMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqLIWJHrOyqNMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive34lXGGRt8YMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academic0Cy8nfsTmZMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index0PlJCdV3lnMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediatRSbr0y2pjMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsemisgl6lJeMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineXrTG4AX8ZFMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesSPKgiRAGlvMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexK6GA9OMZKQMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesjpzOArIFNyMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleo3b1W9Fix9Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy8dBWkUtVbIMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_937DN9MxpMEFMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93WoDDUtS9NsMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorCfXzXxm5PjMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anydu83tNmOtUMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidinglO6bRQKuIHMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexPNiPKM455uMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrornG5VPyVjyHMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounEtT2ipq6mKMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourceseAHVmR7ugnMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamPZVupOt49AMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyw2EOEdY934Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsDh7H2h9hlsMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userMECsLPlgmnMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsHujk2Ax0UeMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240927120726/pages/workshops.vue")
  }
]